// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  UrlServicesOperado:
    "https://apioperadorcontenedores.sistemahillebrandgori.cl/api/v1",
  UrlServices:
    "https://apioperadorcontenedores.sistemahillebrandgori.cl/api/v1",
  // UrlServicesOperado: "https://qa-api-opcont.sistemahillebrandgori.cl/api/v1",
  // UrlServices: "https://qa-api-opcont.sistemahillebrandgori.cl/api/v1",
  // UrlServicesOperado: "https://localhost:44340/api/v1",
  // UrlServices: "https://localhost:44340/api/v1",
  production: false,
  defaultauth: "fackbackend",
  firebaseConfig: {
    apiKey: "",
    authDomain: "",
    databaseURL: "",
    projectId: "",
    storageBucket: "",
    messagingSenderId: "",
    appId: "",
    measurementId: "",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
