<footer class="footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6">{{ year }} Dreamslab</div>
      <div class="col-sm-6">
        <div class="text-sm-end d-none d-sm-block">
          Operador de contenedores - Hillebrand Gori v1.0.20
        </div>
      </div>
    </div>
  </div>
</footer>
