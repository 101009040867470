import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [

    {
        id: 1,
        label: 'MENUITEMS.MENU.TEXT',
        isTitle: true
    },
    {
        id: 2,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'uil-home-alt',
        link: '/',
    },
    {
        id: 3,
        isLayout: true
    },
    {
        id: 4,
        label: 'MENUITEMS.APPS.TEXT',
        isTitle: true
    },
    {
        id: 5,
        label: 'MENUITEMS.TATC.TEXT',
        icon: '',
        link: '/tatc',
    },
    {
        id: 6,
        label: 'MENUITEMS.TSTC.TEXT',
        icon: '',
        link: '/tstc',
    },
    {
        id: 7,
        label: 'MENUITEMS.TRASPASO.TEXT',
        icon: '',
        link: '/traspaso',
    },
    {
        id: 8,
        label: 'MENUITEMS.PLAZOSPRORROGAS.TEXT',
        icon: '',
        link: '/plazos-vigencia-titulos',
    },
    {
        id: 9,
        label: 'MENUITEMS.PLAZOSPRORROGASTSTC.TEXT',
        icon: '',
        link: '/plazos-vigencia-titulos-tstc',
    },
    {
        id: 10,
        label: 'MENUITEMS.INVENTARIOS.TEXT',
        icon: '',
        link: '/inventario',
    },
    {
        id: 11,
        label: 'MENUITEMS.FISCALIZACION.TEXT',
        icon: '',
        link: '/control-fiscalizacion',
    },
    {
        id: 12,
        label: 'MENUITEMS.CAMBIOCONTRASENA.TEXT',
        icon: '',
        link: '/cambio-contrasena',
    },
    {
        id: 13,
        label: 'MENUITEMS.DOCUMENTACION.TEXT',
        icon: '',
        link: '/documentacion',
    }
];

export const MENUADUANA: MenuItem[] = [

    {
        id: 1,
        label: 'MENUITEMS.MENU.TEXT',
        isTitle: true
    },
    {
        id: 2,
        label: 'MENUITEMS.PLAZOSPRORROGAS.TEXT',
        icon: '',
        link: '/plazos-vigencia-titulos',
    },
    
    {
        id: 3,
        label: 'MENUITEMS.PLAZOSPRORROGASTSTC.TEXT',
        icon: '',
        link: '/plazos-vigencia-titulos-tstc',
    },
    {
        id: 4,
        label: 'MENUITEMS.INVENTARIOS.TEXT',
        icon: '',
        link: '/inventario',
    },
    {
        id: 5,
        label: 'MENUITEMS.FISCALIZACION.TEXT',
        icon: '',
        link: '/control-fiscalizacion',
    },
    {
        id: 6,
        label: 'MENUITEMS.CAMBIOCONTRASENA.TEXT',
        icon: '',
        link: '/cambio-contrasena',
    },
    {
        id: 7,
        label: 'MENUITEMS.DOCUMENTACION.TEXT',
        icon: '',
        link: '/documentacion',
    }
];